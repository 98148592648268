import axios from 'axios';
import { useCallback, useEffect, useState } from 'react'
import {node_loan_should_show } from '../../../assets/jss/incept-sustainability-variables';


const useLoanHandler = () => {
  const [showLoan, setShowLoan] = useState<boolean>(false);
  const token = localStorage.getItem('token');
  const authStr = 'Bearer ' + token;

  const fetchShouldShowLoan = useCallback(async () => {
    const response = await axios.get(`${node_loan_should_show}`, {
        headers: {
            Authorization: authStr
        }
    });
    setShowLoan(response.data);  
   }, []);

   useEffect(() => {
    fetchShouldShowLoan();
   }, [fetchShouldShowLoan]);

   return {showLoan};
}

export default useLoanHandler;