import React from 'react';
import { IconButton, Snackbar } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import ShareIcon from "@mui/icons-material/Share";

interface CopyToClipboardButtonProps {
  stringToShare: string;
  toolTipText: string;
  onClick?: () => void;
}

const CopyToClipboardButton = ({ stringToShare, toolTipText, onClick }: CopyToClipboardButtonProps) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(stringToShare);
    if (onClick) {
      onClick(); 
    }
  };

  return (
    <>
      <Tooltip title={toolTipText}>
        <IconButton onClick={handleClick} color="primary">
          <ShareIcon />
        </IconButton>
      </Tooltip>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="Copied to clipboard"
      />
    </>
  );
};

export default CopyToClipboardButton;