import axios from 'axios';
import { useCallback, useEffect, useState } from 'react'
import {node_assessment_should_show } from '../../../assets/jss/incept-sustainability-variables';


const useConsultRambollHandler = () => {
  const [showConsultRamboll, setShowConsultRamboll] = useState<boolean>(false);
  const token = localStorage.getItem('token');
  const authStr = 'Bearer ' + token;

  const fetchShouldConsultRamboll = useCallback(async () => {
    const response = await axios.get(`${node_assessment_should_show}`, {
        headers: {
            Authorization: authStr
        }
    });
    setShowConsultRamboll(response.data);  
   }, []);

   useEffect(() => {
    fetchShouldConsultRamboll();
   }, [fetchShouldConsultRamboll]);

   return {showConsultRamboll};
}

export default useConsultRambollHandler;