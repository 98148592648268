import React from 'react';
import { useLocation } from 'react-router-dom'; // Import hooks from react-router-dom
import clientConfig from 'client-config.js';
import jwt from 'jwt-decode';
import NewAppIFrame from 'components/NewAppIFrameViewer';
import useKnowledgebaseHandler from 'components/Sidebar/hooks/useKnowledgebaseHandler';
import { useSharedTokenInfo } from 'context/auth_context/TokenContext';

const KnowledgeBase2 = (props) => {
  const location = useLocation();
  const id = location.pathname.split('/').pop();

  const {authString} = useSharedTokenInfo();
  const jwtToken = authString ? jwt(authString) : null;
  
  const { knowledgeBaseCategories } = useKnowledgebaseHandler();
  const name = knowledgeBaseCategories.find((category) => category.id === Number(id))?.name;

 
  // Ensure token is valid and categoryId is available
  if (!jwtToken || !id) {
    return null;
  }
  const iframePagePath = name ?`knowledgebase/${id}?name=${name}` : `knowledgebase/${id}`;



  return (
    <NewAppIFrame
      iFrameOrigin={clientConfig.nextUrl} // Production Mode
      // iFrameOrigin={'http://localhost:3010/'} // Development Mode
      iFramePagePath={iframePagePath}
      title="Knowledge Base"
      authToken={authString}
      userId={jwtToken.data.user.id}
    />
  );
};
export default KnowledgeBase2;
