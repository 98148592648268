/*
*  Description: this file contains a constant variable that defines
*   the code for the sidebar menu
*
*/
/**
 * Alexa documentation: smDown: If true, screens this size and up will be hidden.
 *
 * smDown: If true, screens this size and down will be hidden.
 * https://material-ui.com/components/hidden/
 */

/*eslint-disable*/
import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
// @mui/material components
import withStyles from "@mui/styles/withStyles";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import IconExpandMore from '@mui/icons-material/ExpandMore'
import { useLocation } from 'react-router-dom';
import { ourOrange } from 'assets/jss/incept-sustainability-variables.jsx';
import Plausible from 'plausible-tracker';
import Collapse from '@mui/material/Collapse';
import jwt from "jwt-decode";

const { trackEvent } = Plausible();
// core components

import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.jsx";

//Used for Translations
import LanguageSelect from "components/LanguageSelect/LanguageSelect.js";
import { withTranslation } from 'react-i18next';
import useKnowledgebaseHandler from "./hooks/useKnowledgebaseHandler";
import { reloadIframes } from "lib/reloadIframes";
import useAssessmentHandler from "./hooks/useAssessmentHandler";
import useLoanHandler from "./hooks/useLoanHandler";
import useConsultRambollHandler from "./hooks/useConsultRambollHandler";

function isRoleAllowed(role, prop) {
	const restrictedNames = ["My actions"];

	//if role is any of the roleManager and prop is restricted, return false
	//If role is of type learner manager and path is /analytics_overview, return false: these roles haver their own analytics
	//  overview page
	//If role is manager and custom manager (not learner) and path is /analytics_overview_lmanager,
	//  return false: these roles have overview page the analytics_overview one
	//if path is knowledge base and showKB is false, return false
	//if current user role is not allowed, return false

    const roleManagerConditions = {
        "Learner Manager": prop.path === "/analytics_overview",
        "Manager": prop.path === "/analytics_overview_lmanager",
        "Custom Manager": prop.path === "/analytics_overview_lmanager" || restrictedNames.includes(prop.name)
    };

    const isRoleManager = Object.keys(roleManagerConditions).some(roleManager => role===roleManager
							&& roleManagerConditions[roleManager]);

    return !(isRoleManager);
}

function isRoleKBAllowed(prop, showKB) {
	const knowledgeBaseNames = ["/KB", "/Knowledge Base Sub"];
	const isKnowledgeBasePath = knowledgeBaseNames.includes(prop.path) && !showKB;

	return !(isKnowledgeBasePath);
}

function isRoleBothAllowed(role, prop) {
	const isRoleDisallowed = !role.includes(prop.roleD) && prop.roleD !== "Both" && role !== "Both";

	return !(isRoleDisallowed);
}

function activeRoute(routeName) {
	return window.location.href.indexOf(routeName) > -1 ? true : false;
}

function isActiveTupleRoute(route1, route2) {
    return activeRoute(route1) || activeRoute(route2);
}

function isPropNameInSubMenu(subMenuNames, propName) {
    return subMenuNames.includes(propName);
}



const Sidebar = ({ ...props }) => {
	// verifies if routeName is the one active (in browser input)
	const { classes, color, logo, languages, logo2, routes, showModTypes, iscustomManager, showKB, role, renderRedirect,
		 languageBasedUserCountry, logoutprofile, t, companyId, assessmentCategories: assessmentCategoriesObject } = props;

	const [selectedAssessmentCategory, setSelectedAssessmentCategory] = useState(0);
	const [selectedKnowledgebaseCategory, setSelectedKnowledgebaseCategory] = useState(null);

	const location = useLocation();

	// Get the language, showKnowledgebase, and knowledgebaseCategories from the useKnowedlgbaseHandler hook
	const {
		showKnowledgebase,
		knowledgeBaseCategories,
	  } = useKnowledgebaseHandler();

	  const {showAssessments}= useAssessmentHandler();
	  const {showLoan} = useLoanHandler();
	  const {showConsultRamboll} = useConsultRambollHandler();

	// New state for managing open/closed state of submenus
	const [openMenus, setOpenMenus] = useState({});
	
	const token = localStorage.getItem('token');
	if (!token) {
		return renderRedirect(true);
	}
	const analyticsSubMenuNames = ["Analytics", "Completion log", "Employee feedback", "Ideas and comments"];
	const moduleCateSubMenuNames = ["E-Learnings", "E-Learnings Categories"];
	

	const needsFiltering = [{
		path : "/loan",
		show: showLoan
	},
	{
		path : "/assessments",
		show: showAssessments
	},
	{
		path : "/consult-ramboll",
		show: showConsultRamboll
	},
	{
		path : "/knowledge-base",
		show: showKnowledgebase
	}];

	// Filter the routes based on the needsFiltering array
	const filteredRoutes = routes.filter(route => {
		const routeNeedsFiltering = needsFiltering.find(filter => filter.path === route.path);
		return routeNeedsFiltering ? routeNeedsFiltering.show : true;
	})

	// Using separate routes for Swedbank, since they have some of the tabs that are unique to them
	const modularRoutes = filteredRoutes;


	// New function to toggle submenus
	const toggleSubmenu = (propName) => {
		setOpenMenus(prevState => ({
			...prevState,
			[propName]: !prevState[propName]
		}));
	};

	let links = (
		<List className={classes.list}>
			{modularRoutes.filter((prop) => prop.name !== 'My Actions')
				.map((prop, key) => {
					
					if (prop.name === 'Knowledge Base' && showKnowledgebase === false) return null; // Do not show the new Knowledge Base tab if we return false from the API call

					//check if prop is invisible and if it is not a submenu
					if (prop.invisible && !prop.submenu) return null;

					//check if role is allowed, checking permissions
					if (!isRoleAllowed(role, prop)) return null;
					if (!isRoleKBAllowed(prop, showKB)) return null;
					if (!isRoleBothAllowed(role, prop)) return null;

					//Format menu name
					let propNameNoSpace = prop.name.replace(/\s/g, "");

					//Check if it is submenu
					let subMenuClass = classes.hideMenu;
					//Icon for submenu
					let dropDownIcon = <IconExpandMore data-dir={"down"} id={`is_arrowDown${propNameNoSpace}`} className={classes.menuArrow} />;

					//check if submenu in analytics or KB
					if (prop.path === '/assessments') {
						subMenuClass = classes.showMenu;
					}

					if (isActiveTupleRoute("admin/analytics_","custom_activity")
						&& isPropNameInSubMenu(analyticsSubMenuNames, prop.name)) {
						subMenuClass = classes.showMenu;
					}
					else if (isActiveTupleRoute("admin/guides", "admin/guidessub")
							&& isPropNameInSubMenu(moduleCateSubMenuNames, prop.nameGen)) {
						subMenuClass = classes.showMenuKB;
					}

					//If prop contains id (for example, knowledge base sub), add it to the path
					let param = (prop.id !== undefined && prop.id!=="") ? "/" + prop.id : "";

					//If current value is Logout
					let navlink = prop.name !== "Logout" ? prop.layout + prop.path : location.pathname;
					//If current value is guidessub
					if (prop.path.includes("guides") && prop.submenu)
						navlink = prop.layout + prop.path + "/" + prop.id;

					/*
					* Styling
					*/

					// This are the classes for highlitghting the menu a user clicks on or stands on
					//classNames is a function that accepts an object where the keys are class names and the values are conditions.
					let listItemClasses = classNames({
						[" " + classes[color]]: activeRoute(prop.layout + prop.path + param),
						[" " + subMenuClass]: prop.submenu ? true : false,
					});
					const whiteFontClasses = classNames({
						[" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
					});

					//This adds more padding if it is a submenu
					let morePadding = "";
					if (prop.submenu) {
						morePadding = "40px";
					}

					if (prop.name === 'Assessments') {
						return (
							<React.Fragment key={key}>
								<ListItem
									style={{
										display: "flex",
										paddingRight: morePadding
									}}
									button
									className={classes.itemLink + listItemClasses}
									onClick={() => {
										toggleSubmenu(prop.name);
										reloadIframes();
									}}
									component={NavLink} // Make the ListItem a NavLink
									to={prop.layout + prop.path} // Link to the desired path
								>
									<img className={classes.itemIcon} style={{marginRight: '15px', marginLeft: '5px'}} src={activeRoute(prop.layout + prop.path + param) === false ? prop.icon[0] : prop.icon[1]} alt="nav item icon" />
									<ListItemText
											primary={t(prop.name)}
											className={classNames(classes.itemText, whiteFontClasses, {
												[classes.itemTextRTL]: props.rtlActive
											})}
											disableTypography={true}
											style={{
												fontFamily: 'gotham-font-book',
												fontWeight: activeRoute(prop.layout + prop.path + param) ? 'bold' : '',
												color: activeRoute(prop.layout + prop.path + param) ? `${ourOrange}` : 'black'
											}}
									/>
									<IconExpandMore data-dir={"down"} id={`is_arrowDown${propNameNoSpace}`} className={classes.menuArrow} />;
								</ListItem>
								<Collapse in={openMenus[prop.name]} timeout="auto" unmountOnExit>
									<List component="div" disablePadding>
										{assessmentCategoriesObject.map((item, index) => {
											return (
												<Link
													to={`${prop.layout}${prop.path}/${item.assessmentCategoryId}/`}
													key={index}
													onClick={() => {
														setSelectedAssessmentCategory(index);
														reloadIframes();
													}}
												>
												<ListItem
													button
													style={{
														backgroundColor: selectedAssessmentCategory === index ? '#f5f5f5' : 'white',
														paddingLeft: '0px',
														borderTopLeftRadius: '30px',
														borderBottomLeftRadius: '30px',
														marginLeft: '40px',
													}}
												>
													<ul style={{ listStyleType: "circle", padding: "0 0 0 60px", color: ourOrange }}>
														<li></li>
													</ul>
													<div style={{
														color: selectedAssessmentCategory === index ? ourOrange : 'black'
													}}>
														<ListItemText
															secondary={item.assessmentCategoryName}
														/>
													</div>
											</ListItem>
										</Link>
											)
										})}
									</List>
								</Collapse>
							</React.Fragment>
						);
					} else if (prop.name === 'Knowledge Base') {
						return (
							<React.Fragment key={key}>
								<ListItem
									style={{
										display: "flex",
										paddingRight: morePadding
									}}
									button
									className={classes.itemLink + listItemClasses}
									onClick={() => {
										toggleSubmenu(prop.name);
										reloadIframes()
									}}
									component={NavLink} // Make the ListItem a NavLink if there are no categories
									to={prop.layout + prop.path + "/-1"} // This signals that no categories are available
								>		
									<img className={classes.itemIcon} style={{marginRight: '15px', marginLeft: '5px'}} src={activeRoute(prop.layout + prop.path + param) === false ? prop.icon[0] : prop.icon[1]} alt="nav item icon" />
									<ListItemText
											primary={t(prop.name)}
											className={classNames(classes.itemText, whiteFontClasses, {
												[classes.itemTextRTL]: props.rtlActive
											})}
											disableTypography={true}
											style={{
												fontFamily: 'gotham-font-book',
												fontWeight: activeRoute(prop.layout + prop.path + param) ? 'bold' : '',
												color: activeRoute(prop.layout + prop.path + param) ? `${ourOrange}` : 'black'
											}}
									/>
									{
										knowledgeBaseCategories.length > 0 && 
										<IconExpandMore data-dir={"down"} id={`is_arrowDown${propNameNoSpace}`} className={classes.menuArrow} />
									}
									
								</ListItem>
								<Collapse in={openMenus[prop.name]} timeout="auto" unmountOnExit>
									<List component="div" disablePadding>
										{knowledgeBaseCategories.map((item, index) => {
											return (
												<Link
													to={`${prop.layout}${prop.path}/${item.id}`}
													key={index}
													onClick={() => {
														setSelectedKnowledgebaseCategory(index);
														reloadIframes();
													}}
												>
												<ListItem
													button
													className={classes.nested}
													style={{
														backgroundColor: selectedKnowledgebaseCategory === index ? '#f5f5f5' : 'white',
														paddingLeft: '0px',
														borderTopLeftRadius: '30px',
														borderBottomLeftRadius: '30px',
														marginLeft: '40px',
													}}
												>
													<ul style={{ listStyleType: "circle", padding: "0 0 0 60px", color: ourOrange }}>
														<li></li>
													</ul>
													<div style={{
														color: selectedKnowledgebaseCategory === index ? ourOrange : 'black'
													}}>
														<ListItemText
															secondary={item.name}
														/>
													</div>
											</ListItem>
										</Link>
											)
										})}
									</List>
								</Collapse>
							</React.Fragment>
						);
					} else {
						return (
							<NavLink
							to={navlink}
							className={classes.item}
							onClick={prop.name == "Logout" ? logoutprofile : (activeRoute("admin/KB") || activeRoute("admin/knowledgebasesub")) ? function () { trackEvent("KB " + prop.name); return; } : function () { return; }}
							activeClassName="active"
							style={prop.submenu ? { marginLeft: "10px" } : {}}
							key={key}
						>

							<ListItem style={{
								display: "flex",
								paddingRight: morePadding
							}} button className={classes.itemLink + listItemClasses}>
								{prop.submenu && <ul style={{ listStyleType: "circle", padding: "0 0 0 20px", color: ourOrange }}><li></li></ul>}
								{!prop.submenu && <img className={classes.itemIcon} style={{marginRight: '15px', marginLeft: '5px'}} src={activeRoute(prop.layout + prop.path + param) === false ? prop.icon[0] : prop.icon[1]} alt="nav item icon" />}
								<ListItemText
											primary={t(prop.name)}
											className={classNames(classes.itemText, whiteFontClasses, {
												[classes.itemTextRTL]: props.rtlActive
											})}
											disableTypography={true}
											style={{
												fontFamily: 'gotham-font-book',
												fontWeight: activeRoute(prop.layout + prop.path + param) ? 'bold' : '',
												color: activeRoute(prop.layout + prop.path + param) ? `${ourOrange}` : 'black'
											}}
								/>

								{(prop.name === "Analytics"  || showModTypes && prop.name === "E-Learnings") && dropDownIcon}

							</ListItem>
						</NavLink>
						)
					}
				})}
		</List>
	);

	//Logo section is here!
	let brand = (
		<div className={classes.logo}>
			<a

				className={classNames(classes.logoLink, {
					[classes.logoLinkRTL]: props.rtlActive
				})}
				target="_blank"
				style={{ textAlign: "center" }}
			>
				<div className={classes.logoImage}>
					<img src={logo} className={classes.img} width="120" />
				</div>
			</a>
		</div>
	);
	//brand 2
	let brand2 = (
		<div className={classes.logoIncept}>
			<a
				href="https://www.inceptsustainability.com/"
				className={classNames(classes.logoLink, {
					[classes.logoLinkRTL]: props.rtlActive
				})}
				style={{ textAlign: "center" }}
				target="_blank"
			>
				<div className={classes.logoImage}>
					<img src={logo2} alt="logo" className={classes.imgBottom} />
				</div>
			</a>
		</div>
	);

	return (
		<div>
			{/* For mobile */}
			<Hidden mdUp implementation="css">
				<Drawer
					variant="temporary"
					anchor={props.rtlActive ? "left" : "right"}
					open={props.open}
					classes={{
						paper: classNames(classes.drawerPaper, {
							[classes.drawerPaperRTL]: props.rtlActive
						})
					}}
					onClose={props.handleDrawerToggle}
					ModalProps={{
						keepMounted: true // Better open performance on mobile.
					}}
				>
					{brand}
					<div className={classes.sidebarWrapper}>
						{links}
					</div>
					{(languages.length > 1) &&
						<div style={{ padding: "0px 35px" }}>
							<LanguageSelect
							languageBasedUserCountry={languageBasedUserCountry}
							complanf={languages} />
						</div>
					}
					{brand2}
					{/*backgroundStyle*/}
				</Drawer>
			</Hidden>
			{/*For desktop version*/}
			<Hidden mdDown implementation="css">
				<Drawer
					anchor={props.rtlActive ? "right" : "left"}
					variant="permanent"
					open
					classes={{
						paper: classNames(classes.drawerPaper, {
							[classes.drawerPaperRTL]: props.rtlActive
						})
					}}
				>
					{brand}
					<div className={classes.sidebarWrapper}>
						{links}
					</div>
					{(languages.length > 1) &&
						<div style={{ padding: "0px 35px" }}>
							<LanguageSelect
							languageBasedUserCountry={languageBasedUserCountry}
							complanf={languages} />
						</div>
					}
					{brand2}
					{/*backgroundStyle*/}
				</Drawer>
			</Hidden>
		</div>
	);
};

Sidebar.propTypes = {
	classes: PropTypes.object.isRequired,
	rtlActive: PropTypes.bool,
	handleDrawerToggle: PropTypes.func,
	bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
	logo: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object),
	open: PropTypes.bool
};

export default withTranslation()(withStyles(sidebarStyle)(Sidebar));