import { useContext } from "react";
import { n_comp_nly_3, node_ideas_page } from "assets/jss/incept-sustainability-variables";
import axios from "axios";
import i18n from "i18n";
import { TokenContext, UseSharedTokenInfo } from "context/auth_context/TokenContext";

import { useIdeaContext } from "../../../components/AnalyticsIdeas/context/IdeasContext";
import { useCountriesContext } from "../../../components/AnalyticsIdeas/context/IdeasCountriesContext";
import { useIdeasDepartmentsContext } from "../../../components/AnalyticsIdeas/context/IdeasDepartmentsContext";
import { useIdeasFilterContext } from "../../../components/AnalyticsIdeas/context/IdeasFilterContext";
import { useModulesContext } from "../../../components/AnalyticsIdeas/context/IdeasModulesContext";
import { useModalActionsDivContext } from "../../../components/AnalyticsIdeas/context/IdeasModalActionDivContext";

const BEARER_PREFIX = 'Bearer ';

export const useFetchDataForIdeasList = () => {
	const ideasFilter = useIdeasFilterContext();
	const ideasDepartmentFilter = useIdeasDepartmentsContext();
	const ideasModuleFilter = useModulesContext();
	const ideasCountryFilter = useCountriesContext();
	const ideasModalActionsDiv = useModalActionsDivContext();
	const ideaData = useIdeaContext();
    

    const { authString } = useContext(TokenContext);

    const fetchDataForIdeasList = async ( isArchived = false, depid = "", countries = []) => {        
        try {
            const insData = {
                headers: { Authorization: BEARER_PREFIX + authString },
                params: {
                    department: depid,
                    countries: JSON.stringify(countries)
                }
            };
    
            const useLang = i18n.language;
            const type = isArchived ? 'archived' : 'all';      
            let urlApi = n_comp_nly_3 + type + "?wpml_language=" + useLang;      

            if (depid==="" && countries.length===0) 
                urlApi = node_ideas_page;

            const response = await axios.get(urlApi, insData);
    
            ideasFilter.setFilterFlags({department: (depid!=="") ? 1:0,country:(countries.length>0) ? 1:0});
    
            ideasDepartmentFilter.setDepartments(response.data.departments);
            ideasModalActionsDiv.setlistActionsdiv(response.data.activeActions);
            ideasFilter.setManagers(response.data.managers);
            ideasFilter.setListItems(response.data.ideas);
            ideasFilter.setIdeasFiltered(response.data.ideas);
            ideasModuleFilter.setModules(response.data.modules);
            const labelIdeasA = response.data.labels_ideas.sort((a, b) =>
                a.label > b.label ? 1 : b.label > a.label ? -1 : 0
            );
    
            ideasFilter.setIdeasInfo({
                ideas: response.data.ideas,
                active: new Array(response.data.ideas.length).fill(false),
                checked: new Array(response.data.ideas.length).fill(false),
                nideas: response.data.nideas,
                nparticipants: response.data.pideas,
                labelsideas: labelIdeasA,
                allusersideas: response.data.allusersideas,
                departmentStats: response.data.deptinfo,
                loadingfirst: false,
                listCleared: false,
            });
    
            ideaData.setnideasSt(response.data.ideas.length);
            ideasCountryFilter.setCountries(response.data.countries);
    
        } catch (err) {
            console.log(err);
        }
    }
    return fetchDataForIdeasList;
};

export default useFetchDataForIdeasList;