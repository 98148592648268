/*!
 * Description: this file contains the main base component for
 *  the rendering the main page view of analytics
 *
 * Components: AnalyticsPage
 */
import React, {useState, useEffect, useContext} from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import withStyles from "@mui/styles/withStyles";
// core components
import Grid from "@mui/material/Grid";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import {
    n_comp_pck, ourGreen, ourDarkGreen, ourBlueLight,
    ourBlue, isAuthenticated
} from "assets/jss/incept-sustainability-variables.jsx";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import axios from "axios";
import CardAnalytics from "components/CardAnalytics/Card.jsx";
import CardAnalyticsHeader from "components/CardAnalytics/CardHeader.jsx";
import CardAnalyticsBody from "components/CardAnalytics/CardBody.jsx";

import FakeButton from "components/CustomButtons/FakeButton";
import ActionButton from 'components/CustomButtons/ActionButton';
//table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import {Link} from "react-router-dom";

//styles
import analyticsStyle from "assets/jss/views/analyticsStyle.jsx";
import {useMediaQuery} from 'react-responsive';

import "react-circular-progressbar/dist/styles.css";
import ActiveEmployees_tooltip from "components/Tooltip/ActiveEmployees_tooltip";
//Translate
import {withTranslation, Trans} from 'react-i18next';
import i18n from "i18next";
import i18next from "i18next";

// Token
import {TokenContext} from "../../context/auth_context/TokenContext.jsx";
import PageWrapper from "components/PageWrapper/PageWrapper.jsx";


const useStyles = (theme) => ({
    smallButton: ({color, textColor}) => ({
        backgroundColor: color,
        color: textColor,
        border: "0px",
        borderRadius: "20px",
        fontSize: "14px",
        padding: "5px 30px",
        width: "100%"
    })
});

function withMyHook(Component) {
    return function WrappedComponent(props) {
        const mobile = useMediaQuery({query: `(max-width: 1200px)`});
        return <Component {...props} isMobile={mobile}/>;
    }
}

const BEARER_PREFIX = 'Bearer ';

function AnalyticsPage(props) {
    const {classes, t, content} = props;
    const [completionLog, setcompletionLog] = useState({registered: 0, active: 0, completed: 0});
    const [ideasComments, setideasComments] = useState({total: 0, active: 0});
    const [companyAnalytics, setcompanyAnalytics] = useState({average: 0, empty: 0});
    const [loading, setLoading] = useState(true);

    const { authString } = useContext(TokenContext);


    useEffect(() => {
        // You need to restrict it at some point
        // This is just dummy code and should be replaced by actual
        if (loading) {
            getncomplpack();
        }
    }, []);

    //Get ideas from backend
    const getncomplpack = async () => {
        if (!isAuthenticated()) {
            this.props.isExpired();
        }

        const useLang = i18n.language;
        const urlapi = n_comp_pck + "statistics?wpml_language=" + useLang;
        axios
            .get(urlapi, {headers: {Authorization: BEARER_PREFIX + authString}})
            .then((response) => {
                console.log(response.data);
                setcompletionLog({
                    registered: response.data.allusers,
                    active: response.data.nactive,
                    completed: response.data.completed
                });
                setideasComments({
                    total: response.data.nideas,
                    active: response.data.pideas
                });
                setcompanyAnalytics({
                    average: response.data.average_sub,
                    empty: response.data.average_empty
                });
                setLoading(false);

                //if (response.data.nideas>this.state.ideasPerPage)
                //	this.setState({showpagination: true});
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }

    return (
        <PageWrapper
            activeOverlay={loading}
        >
            <div className={classes.containerAnalytics}>
                <Grid>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} space={0}>
                            <h2 className={classes.titleHeader}>Analytics Overview</h2>
                        </GridItem>
                    </GridContainer>
                    <GridContainer style={{height: "100%"}}>
                        <GridItem xs={12} sm={12} md={12} lg={4} space={0}
                                  style={props.isMobile ? {marginBottom: 30} : {}}>
                            <CardAnalytics style={{height: "100%"}}>
                                <CardAnalyticsHeader title="Completion log">
                                </CardAnalyticsHeader>
                                <CardAnalyticsBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12} space={0} spaceNoPadding={true}>
                                            <TableContainer>
                                                <Table aria-label="simple table">
                                                    <TableBody>
                                                        <TableRow
                                                            key={"Registered employees"}
                                                        >
                                                            <TableCell component="th" scope="row"
                                                                       className={classes.completionRowTh}>
                                                                {"Registered employees:"}
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}
                                                                       style={{paddingRight: "0"}}
                                                                       align={"right"}>
                                                                <FakeButton
                                                                    style={{
                                                                        width: "80px",
                                                                        padding: "5px 10px"
                                                                    }}
                                                                    color={ourBlueLight}
                                                                    textColor={ourBlue}
                                                                    content={completionLog.registered}/>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            key={"Active employees"}
                                                        >
                                                            <TableCell component="th" scope="row"
                                                                       className={classes.completionRowTh}>
                                                                {"Active employees:"}
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}
                                                                       style={{paddingRight: "0"}}
                                                                       align={"right"}>
                                                                <FakeButton
                                                                    style={{
                                                                        width: "80px",
                                                                        padding: "5px 10px"
                                                                    }}

                                                                    color={ourBlueLight}
                                                                    textColor={ourBlue}
                                                                    content={completionLog.active + "%"}/>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            key={"Completed Employess(all modules)"}
                                                        >
                                                            <TableCell component="th" scope="row"
                                                                       className={classes.completionRowTh}>
                                                                <ActiveEmployees_tooltip
                                                                    tooltipid={"someid"}/>{"that completed all modules:"}
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}
                                                                       style={{paddingRight: "0"}}
                                                                       align={"right"}>
                                                                <FakeButton
                                                                    style={{
                                                                        width: "80px",
                                                                        padding: "5px 10px"
                                                                    }}

                                                                    color={ourBlueLight}
                                                                    textColor={ourBlue}
                                                                    content={completionLog.completed + "%"}/>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </GridItem>
                                        <GridContainer justifyContent="flex-end" alignItems="flex-end"
                                                       direction="column"
                                                       style={{width: "100%"}}>
                                            <GridItem xs={12} sm={12} md={12} space={0} spaceNoPadding={true}
                                                      style={props.isMobile ? {textAlign: "center"} : {
                                                          display: "flex", alignItems: "end"
                                                      }}>
                                                <Link to={"/admin/analytics_completion"}>
                                                    <ActionButton
                                                        isAnalytics={true}
                                                        style={{width: "fit-content", marginBottom: "0"}}
                                                        // onClick={this.toggle}

                                                    >
                                                        See full overview
                                                    </ActionButton>
                                                </Link>
                                            </GridItem>
                                        </GridContainer>
                                    </GridContainer>
                                </CardAnalyticsBody>
                            </CardAnalytics>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={4} space={0}
                                  style={props.isMobile ? {marginBottom: 30} : {}}>
                            <CardAnalytics style={{height: "100%"}}>
                                <CardAnalyticsHeader title="Employee feedback">
                                </CardAnalyticsHeader>
                                <CardAnalyticsBody>
                                    <GridContainer style={{height: "100%"}}>
                                        <GridItem xs={12} sm={12} md={12} space={0} spaceNoPadding={true}>
                                            <TableContainer>
                                                <Table aria-label="simple table">
                                                    <TableBody>
                                                        <TableRow
                                                            key={"Average Submission %"}
                                                        >
                                                            <TableCell component="th" scope="row"
                                                                       className={classes.completionRowTh}>
                                                                {"Average submission %:"}
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}
                                                                       style={{paddingRight: "0"}}
                                                                       align={"right"}>
                                                                <FakeButton
                                                                    style={{
                                                                        width: "80px",
                                                                        padding: "5px 10px"
                                                                    }}
                                                                    color={ourBlueLight}
                                                                    textColor={ourBlue}
                                                                    content={companyAnalytics.average + "%"}/>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            key={"Empty submissions"}
                                                        >
                                                            <TableCell component="th" scope="row"
                                                                       className={classes.completionRowTh}>
                                                                {"Empty submissions %:"}
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}
                                                                       style={{paddingRight: "0"}}
                                                                       align={"right"}>
                                                                <FakeButton
                                                                    style={{
                                                                        width: "80px",
                                                                        padding: "5px 10px"
                                                                    }}
                                                                    color={ourBlueLight}
                                                                    textColor={ourBlue}
                                                                    content={companyAnalytics.empty + "%"}/>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </GridItem>
                                        <GridContainer justifyContent="flex-end" alignItems="flex-end"
                                                       direction="column"
                                                       style={{width: "100%"}}>
                                            <GridItem xs={12} sm={12} md={12} space={0} spaceNoPadding={true}
                                                      style={props.isMobile ? {textAlign: "center"} : {
                                                          display: "flex", alignItems: "end"
                                                      }}>
                                                <Link to={"/admin/analytics_company"}>
                                                    <ActionButton
                                                        isAnalytics={true}
                                                        style={{width: "fit-content", marginBottom: "0"}}
                                                        // onClick={this.toggle}

                                                    >
                                                        See full overview
                                                    </ActionButton>
                                                </Link>
                                            </GridItem>
                                        </GridContainer>
                                    </GridContainer>
                                </CardAnalyticsBody>
                            </CardAnalytics>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={4} space={0}
                                  style={props.isMobile ? {marginBottom: 30} : {}}>
                            <CardAnalytics style={{height: "100%"}}>
                                <CardAnalyticsHeader title="Ideas and comments">
                                </CardAnalyticsHeader>
                                <CardAnalyticsBody>
                                    <GridContainer style={{height: "100%"}}>
                                        <GridItem xs={12} sm={12} md={12} space={0} spaceNoPadding={true}>
                                            <TableContainer>
                                                <Table aria-label="simple table">
                                                    <TableBody>
                                                        <TableRow
                                                            key={"Ideas and Comments"}
                                                        >
                                                            <TableCell component="th" scope="row"
                                                                       className={classes.completionRowTh}>
                                                                {"Ideas and comments:"}
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}
                                                                       style={{paddingRight: "0"}}
                                                                       align={"right"}>
                                                                <FakeButton
                                                                    style={{
                                                                        width: "80px",
                                                                        padding: "5px 10px"
                                                                    }}
                                                                    color={ourBlueLight}
                                                                    textColor={ourBlue}
                                                                    content={ideasComments.total}/>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            key={"% of active Employee"}
                                                        >
                                                            <TableCell component="th" scope="row"
                                                                       className={classes.completionRowTh}>
                                                                {"% of active employees:"}
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}
                                                                       style={{paddingRight: "0"}}
                                                                       align={"right"}>
                                                                <FakeButton
                                                                    style={{
                                                                        width: "80px",
                                                                        padding: "5px 10px"
                                                                    }}
                                                                    color={ourBlueLight}
                                                                    textColor={ourBlue}
                                                                    content={ideasComments.active + "%"}/>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </GridItem>
                                        <GridContainer justifyContent="flex-end" alignItems="flex-end"
                                                       direction="column"
                                                       style={{width: "100%"}}>
                                            <GridItem xs={12} sm={12} md={12} space={0} spaceNoPadding={true}
                                                      style={props.isMobile ? {textAlign: "center"} : {
                                                          display: "flex", alignItems: "end"
                                                      }}>
                                                <Link to={"/admin/analytics_ideas"}>
                                                    <ActionButton
                                                        isAnalytics={true}
                                                        style={{width: "fit-content", marginBottom: "0"}}
                                                        // onClick={this.toggle}

                                                    >
                                                        See full overview
                                                    </ActionButton>
                                                </Link>
                                            </GridItem>
                                        </GridContainer>
                                    </GridContainer>
                                </CardAnalyticsBody>
                            </CardAnalytics>
                        </GridItem>
                    </GridContainer><br></br>

                    <GridContainer>
                    </GridContainer><br></br>

                </Grid>
            </div>
        </PageWrapper>
    )

}

export default withMyHook(withStyles(
    (theme) => ({
        ...analyticsStyle(theme),
        ...useStyles(theme),
    }),
    {withTheme: true},
)(AnalyticsPage));

