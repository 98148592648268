import React from 'react';
import { useLocation } from 'react-router-dom'; // Import hooks from react-router-dom
import clientConfig from 'client-config.js';
import jwt from 'jwt-decode';
import NewAppIFrame from 'components/NewAppIFrameViewer';
import { useSharedTokenInfo } from 'context/auth_context/TokenContext';

function Assessments({ iFramePagePath: propIFramePagePath }) {
  const location = useLocation(); // Get the current location

  const { authString } = useSharedTokenInfo();
  const token = authString;
  const jwtToken = token ? jwt(token) : null;

  // Ensure token is valid
  if (!jwtToken) {
    return null;
  }

  // Determine iFramePagePath
  let iFramePagePath = propIFramePagePath;

  if (!iFramePagePath) {
    // If no prop is provided, construct the path based on the current URL
    iFramePagePath = location.pathname.replace('/admin', ''); // Adjust as needed
  }

  return (
    <NewAppIFrame
      iFrameOrigin={clientConfig.nextUrl} // Production Mode
      // iFrameOrigin={'http://localhost:3010/'} // Development Mode
      iFramePagePath={iFramePagePath}
      title="Assessments"
      authToken={token}
      userId={jwtToken.data.user.id}
    />
  );
}

export default Assessments;