import { useState, useEffect, useCallback } from 'react';
import i18next from 'i18next';
import {KnowledgeBaseCategoryResponse } from '../types/KnowledgeBaseCategory';
import axios from 'axios';
import { node_kb_categories, node_kb_should_show } from '../../../assets/jss/incept-sustainability-variables';


const useKnowledgebaseHandler = () => {
	const language = i18next.language;
	// sessionStorage.setItem('showKnowledgebase', 'true');
	// sessionStorage.setItem('knowledgeBaseCategories', JSON.stringify(MockData));
	// TODO: These must be changed when mock is removed...
	const [showKnowledgebase, setShowKnowledgebase] = useState<null | boolean>(null);	
    const [response, setResponse] = useState<null |KnowledgeBaseCategoryResponse >
		( null);

	const token = localStorage.getItem('token');
	const authStr = 'Bearer '+ token;

	const fetchShouldShow = useCallback(async () => {
		const response = await axios.get(`${node_kb_should_show}?language=${language}`, {
			headers: {
				Authorization: authStr
			}
		});
		setShowKnowledgebase(response.data);
	}, [language]);

	const getCategories = useCallback(async () => {
		const response = await axios.get(`${node_kb_categories}?language=${language}`, {
			headers: {
				Authorization: authStr
			}
		});
		setResponse(response.data);
	}, [language]);

	const fetchAll = useCallback(async () => {
		await Promise.all([fetchShouldShow(), getCategories()]);
	}, [fetchShouldShow, getCategories]);

    useEffect(() => {
		fetchAll();
	  }, [fetchAll]);

	  return {showKnowledgebase : Boolean(showKnowledgebase), knowledgeBaseCategories : response ? response : []};
}


export default useKnowledgebaseHandler;